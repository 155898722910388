import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-important-info',
    templateUrl: './yos-important-info.component.html',
    styleUrls: ['./yos-important-info.component.scss']
})
export class YosImportantInfoComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    description: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
