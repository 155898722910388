import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-yos-who-can-apply',
    templateUrl: './yos-who-can-aplly.component.html',
    styleUrls: ['./yos-who-can-aplly.component.scss']
})
export class YosWhoCanApllyComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    description: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
