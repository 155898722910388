import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

    data: any = {};

    constructor(
        private http: HttpClient
    ) {
        this.initTranslations();
    }

    initTranslations() {
        const langPath = `assets/i18n/i18n.json`;

        this.http.get<{}>(langPath).subscribe(
            translation => {
                this.data = this.joinKeys(translation);
            },
            error => {
                console.log(error);
                this.data = {};
            }
        );
    }

    joinKeys(obj: any, prefix = ''): any {
        return Object.keys(obj).reduce((acc, k) => {
            const pre = prefix.length ? prefix + '.' : '';
            if (typeof obj[k] === 'object') {
                Object.assign(acc, this.joinKeys(obj[k], pre + k));
            } else {
                acc[pre + k] = obj[k];
            }
            return acc;
        }, {});
    }
}
