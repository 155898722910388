import {Component, OnInit} from '@angular/core';
import lgVideo from 'lightgallery/plugins/video';
import {BeforeSlideDetail} from 'lightgallery/lg-events';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    settings = {
        counter: false,
        plugins: [lgVideo]
    };
    onBeforeSlide = (detail: BeforeSlideDetail): void => {
        const {index, prevIndex} = detail;
        console.log(index, prevIndex);
    }

    accordionItems = [
        {
            title: 'sss.question1.question',
            content: 'sss.question1.answer',
            open: false
        },
        {
            title: 'sss.question2.question',
            content: 'sss.question2.answer',
            open: false
        },
        {
            title: 'sss.question3.question',
            content: 'sss.question3.answer',
            open: false
        },
        {
            title: 'sss.question4.question',
            content: 'sss.question4.answer',
            open: false
        },
        {
            title: 'sss.question5.question',
            content: 'sss.question6.answer',
            open: false
        },
        {
            title: 'sss.question6.question',
            content: 'sss.question6.answer',
            open: false
        },
        {
            title: 'sss.question7.question',
            content: 'sss.question7.answer',
            open: false
        },
        {
            title: 'sss.question8.question',
            content: 'sss.question8.answer',
            open: false
        },
        {
            title: 'sss.question9.question',
            content: 'sss.question9.answer',
            open: false
        },
        {
            title: 'sss.question10.question',
            content: 'sss.question10.answer',
            open: false
        },
        {
            title: 'sss.question11.question',
            content: 'sss.question11.answer',
            open: false
        },
        {
            title: 'sss.question12.question',
            content: 'sss.question12.answer',
            open: false
        },
        {
            title: 'sss.question13.question',
            content: 'sss.question13.answer',
            open: false
        },
        {
            title: 'sss.question14.question',
            content: 'sss.question14.answer',
            open: false
        },
        {
            title: 'sss.question15.question',
            content: 'sss.question15.answer',
            open: false
        },
        {
            title: 'sss.question16.question',
            content: 'sss.question16.answer',
            open: false
        },
        {
            title: 'sss.question17.question',
            content: 'sss.question17.answer',
            open: false
        }
    ];

    selectedItem = null;

    toggleAccordionItem(item) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}
