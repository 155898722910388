<!--<div class="offer-area">-->
    <div class="container">
        <div class="section-title">
            <h2 style="color: black">{{'services.slogan' | translate}}</h2>
            <br/>
            <h5>{{'services.description' | translate}}</h5>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <img src="assets/img/services/consultation.png" alt="image">
                    </div>
                    <h3>{{'services.education-consultancy.title' | translate}}</h3>
                    <p style="text-align: justify">{{'services.education-consultancy.description' | translate}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <img class='bx bx-book-reader' src="assets/img/services/welcome.png" alt="image">
                    </div>
                    <h3>{{'services.dormytory-accommodation-welcome.title' | translate}}</h3>
                    <p style="text-align: justify">{{'services.dormytory-accommodation-welcome.description' | translate}}</p>
                    <br/>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-offer-box">
                    <div class="icon">
                        <img class='bx bx-book-reader' src="assets/img/services/translationandnotary.png" alt="image">
                    </div>
                    <h3>{{'services.translation-and-notary.title' | translate}}</h3>
                    <p style="text-align: justify">{{'services.translation-and-notary.description' | translate}}</p>
                    <br/>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <img class='bx bx-book-reader' src="assets/img/services/equivalence.png" alt="image">
                    </div>
                    <h3>{{'services.equivalency.title' | translate}}</h3>
                    <p style="text-align: justify">{{'services.equivalency.description' | translate}}</p>
                    <br/>
                    <br/>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <img class='bx bx-book-reader' src="assets/img/services/tomer.png" alt="image">
                    </div>
                    <h3>{{'services.tomer.title' | translate}}</h3>
                    <p style="text-align: justify">{{'services.tomer.description' | translate}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-offer-box">
                    <div class="icon">
                        <img class='bx bx-book-reader' src="assets/img/services/residence.png" alt="image">
                    </div>
                    <h3>{{'services.residence-permit.title' | translate}}</h3>
                    <p style="text-align: justify">{{'services.residence-permit.description' | translate}}</p>
                    <br/>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <img class='bx bx-book-reader' src="assets/img/services/health-insurance.png" alt="image">
                    </div>
                    <h3>{{'services.health-insurance.title' | translate}}</h3>
                    <p style="text-align: justify">{{'services.health-insurance.description' | translate}}</p>
                    <br/>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
    </div>
<!--</div>-->
