<div class="row">
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
                <img class='bx bx-book-reader' src="assets/img/services/consultation.png" alt="image">
            <a routerLink="/services">
                {{'services.education-consultancy.title' | translate}}
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
                <img class='bx bx-book-reader' src="assets/img/services/welcome.png" alt="image">
            <a routerLink="/services">
                {{'services.dormytory-accommodation-welcome.title' | translate}}
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
                <img class='bx bx-book-reader' src="assets/img/services/translationandnotary.png" alt="image">
            <a routerLink="/services">
                {{'services.translation-and-notary.title' | translate}}
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
                <img class='bx bx-book-reader' src="assets/img/services/equivalence.png" alt="image">
            <a routerLink="/services">
                {{'services.equivalency.title' | translate}}
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
                <img class='bx bx-book-reader' src="assets/img/services/tomer.png" alt="image">
            <a routerLink="/services">
                {{'services.tomer.title' | translate}}
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
                <img class='bx bx-book-reader' src="assets/img/services/residence.png" alt="image">
            <a routerLink="/services">
                {{'services.residence-permit.title' | translate}}
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
                <img class='bx bx-book-reader' src="assets/img/services/health-insurance.png" alt="image">
            <a routerLink="/services">
                {{'services.health-insurance.title' | translate}}
            </a>
        </div>
    </div>
</div>
