<app-header></app-header>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'apply.title' | translate}}</h2>
        </div>
    </div>
</div>

<div class="contact-area m-5">
    <div class="container">
        <div class="section-title">
            <h2>{{'apply.title' | translate}}</h2>
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" id="contact-form">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                minlength="3"
                                maxlength="50"
                                ngModel
                                name="fullname"
                                type="text"
                                #fullname="ngModel"
                                class="form-control"
                                id="fullname"
                                placeholder="{{'apply.full-name' | translate}}">
                            <div class="alert alert-danger" *ngIf="fullname.touched && !fullname.valid">
                                <div *ngIf="fullname.errors.required">{{'apply.required' | translate}}</div>
                                <div *ngIf="fullname.errors.minlength">{{'apply.required' | translate}}</div>
                                <div *ngIf="fullname.errors.maxlength">{{'apply.required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="age"
                                type="text"
                                #age="ngModel"
                                class="form-control"
                                id="age"
                                placeholder="{{'apply.age' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="age.touched && !age.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="email"
                                type="text"
                                #email="ngModel"
                                class="form-control"
                                id="email"
                                placeholder="{{'apply.email' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="email.touched && !email.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group input-group mb-3">
                            <app-country-selector></app-country-selector>
                            <input required
                                ngModel
                                name="phone"
                                type="text"
                                #phone="ngModel"
                                class="form-control"
                                id="phone"
                                placeholder="{{'apply.phone' | translate}}">
                        </div>
                        <div class="alert alert-danger"
                            *ngIf="(phone.touched && !phone.valid)">{{'apply.required' | translate}}</div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="citizenship"
                                type="text"
                                #citizenship="ngModel"
                                class="form-control"
                                id="citizensihip"
                                placeholder="{{'apply.citizenship' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="citizenship.touched && !citizenship.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="turkish-citizenship"
                                type="text"
                                #turkishCitizenship="ngModel"
                                class="form-control"
                                id="turkish-citizenship"
                                placeholder="{{'apply.turkish-citizenship' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="turkishCitizenship.touched && !turkishCitizenship.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="high-school-graduation"
                                type="text"
                                #highSchoolGraduation="ngModel"
                                class="form-control"
                                id="high-school-graduation"
                                placeholder="{{'apply.high-school-graduation' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="highSchoolGraduation.touched && !highSchoolGraduation.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="gpa"
                                type="text"
                                #gpa="ngModel"
                                class="form-control"
                                id="gpa"
                                placeholder="{{'apply.gpa' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="gpa.touched && !gpa.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="exam"
                                type="text"
                                #exam="ngModel"
                                class="form-control"
                                id="exam"
                                placeholder="{{'apply.exam' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="exam.touched && !exam.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="exam-result"
                                type="text"
                                #examResult="ngModel"
                                class="form-control"
                                id="exam-result"
                                placeholder="{{'apply.exam-result' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="examResult.touched && !examResult.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="city"
                                type="text"
                                #city="ngModel"
                                class="form-control"
                                id="city"
                                placeholder="{{'apply.city' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="city.touched && !city.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="university"
                                type="text"
                                #university="ngModel"
                                class="form-control"
                                id="university"
                                placeholder="{{'apply.university' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="university.touched && !university.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="faculty"
                                type="text"
                                #faculty="ngModel"
                                class="form-control"
                                id="faculty"
                                placeholder="{{'apply.faculty' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="faculty.touched && !faculty.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="department"
                                type="text"
                                #department="ngModel"
                                class="form-control"
                                id="department"
                                placeholder="{{'apply.department' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="department.touched && !department.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required
                                ngModel
                                name="language"
                                type="text"
                                #language="ngModel"
                                class="form-control"
                                id="language"
                                placeholder="{{'apply.language' | translate}}">
                            <div class="alert alert-danger"
                                *ngIf="language.touched && !language.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required
                                ngModel
                                #message="ngModel"
                                name="message"
                                id="message"
                                cols="30"
                                rows="5"
                                class="form-control"
                                placeholder="{{'apply.message' | translate}}"></textarea>
                            <div class="alert alert-danger"
                                *ngIf="message.touched && !message.valid">{{'apply.required' | translate}}</div>
                        </div>
                    </div>
                    <div class="alert alert-success"
                        *ngIf="status === 'success'">{{'apply.success-message' | translate}}</div>
                    <div class="alert alert-danger" *ngIf="status === 'fail'">{{'apply.fail-message' | translate}}</div>
                    <div class="col-lg-12 col-md-12">
                        <button (click)="submit(contactForm)" class="default-btn" [class.disabled]="!contactForm.valid">
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                            <span class="label">{{'apply.button' | translate}}</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</div>
