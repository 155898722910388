import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-how-to-apply-page',
  templateUrl: './how-to-apply-page.component.html',
  styleUrls: ['./how-to-apply-page.component.scss']
})
export class HowToApplyPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
