<app-header></app-header>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'yos.scores.title' | translate}}</h2>
        </div>
    </div>
</div>

<div class="story-area ptb-100">
    <app-info [title]="'yos.scores.title' | translate" imgUrl="assets/img/yos/scores.jpg" [description]="'yos.scores.description' | translate"></app-info>

    <div class="container">
        <div class="recent-orders-table table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>{{'yos.scores.table.headers.departmnets' | translate}}</th>
                    <th>{{'yos.scores.table.headers.1st-degree-cities' | translate}}</th>
                    <th>{{'yos.scores.table.headers.2st-degree-cities' | translate}}</th>
                    <th>{{'yos.scores.table.headers.3st-degree-cities' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{{'yos.scores.table.departments.medicine' | translate}}</td>
                    <td>98</td>
                    <td>95</td>
                    <td>95</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.dentist' | translate}}</td>
                    <td>93</td>
                    <td>93</td>
                    <td>93</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.pharmacy' | translate}}</td>
                    <td>90</td>
                    <td>95</td>
                    <td>91</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.law' | translate}}</td>
                    <td>90</td>
                    <td>85</td>
                    <td>80</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.nutrition-and-diabetic' | translate}}</td>
                    <td>90</td>
                    <td>80</td>
                    <td>74</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.architecture' | translate}}</td>
                    <td>90</td>
                    <td>80</td>
                    <td>76-70</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.pt-and-r' | translate}}</td>
                    <td>85</td>
                    <td>83</td>
                    <td>80</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.civil-engineering' | translate}}</td>
                    <td>80-77</td>
                    <td>77-73</td>
                    <td>76-70</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.electrical-electronics-eng' | translate}}</td>
                    <td>80-77</td>
                    <td>77-73</td>
                    <td>76-70</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.industrial-enginering' | translate}}</td>
                    <td>80-77</td>
                    <td>77-73</td>
                    <td>76-70</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.veterinary-medicine' | translate}}</td>
                    <td>80</td>
                    <td>80</td>
                    <td>80</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.psychology' | translate}}</td>
                    <td>80</td>
                    <td>70</td>
                    <td>76-70</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.mechanical-engineering' | translate}}</td>
                    <td>78-74</td>
                    <td>75-68</td>
                    <td>70-58</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.computer-science' | translate}}</td>
                    <td>78-74</td>
                    <td>75-68</td>
                    <td>70-58</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.neval-engineering' | translate}}</td>
                    <td>76-67</td>
                    <td>71-60</td>
                    <td>60-45</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.business-economics' | translate}}</td>
                    <td>75</td>
                    <td>75</td>
                    <td>75</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.teaching' | translate}}</td>
                    <td>76-60</td>
                    <td>67-55</td>
                    <td>60-40</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.international-relations-logistics' | translate}}</td>
                    <td>70</td>
                    <td>70</td>
                    <td>70</td>
                </tr>
                <tr>
                    <td>{{'yos.scores.table.departments.aviation' | translate}}</td>
                    <td>70</td>
                    <td>70</td>
                    <td>70</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
