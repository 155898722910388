<app-header></app-header>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'header.menu.application' | translate}}</h2>
        </div>
    </div>
</div>

<p></p>

<section class="information-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="information-image text-center">
                    <img src="../../../../assets/img/information-img.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="information-content">
                    <h2>{{'application.title' | translate}}</h2>
                    <ul class="apply-details">
                        <li>
                            <div class="icon">
                                <i class="flaticon-lcd"></i>
                            </div>
                            <h3>{{'application.application-form' | translate}}</h3>
                            <p>{{'application.application-form.description' | translate}}</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-yoga-2"></i>
                            </div>
                            <h3>{{'application.consultant-support' | translate}}</h3>
                            <p>{{'application.consultant-support.description' | translate}}</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-analytics"></i>
                            </div>
                            <h3>{{'application.universty-offers' | translate}}</h3>
                            <p>{{'application.universty-offers.description' | translate}}</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-login"></i>
                            </div>
                            <h3>{{'application.apllication' | translate}}</h3>
                            <p>{{'application.apllication.description' | translate}}</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-verify"></i>
                            </div>
                            <h3>{{'application.acceptance' | translate}}</h3>
                            <p>{{'application.acceptance.description' | translate}}</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-self-growth"></i>
                            </div>
                            <h3>{{'application.registration' | translate}}</h3>
                            <p>{{'application.registration.description' | translate}}</p>
                        </li>
                        <li>
                            <div class="btn-box">
                                <a routerLink="/apply" class="default-btn"><i class='bx flaticon-check icon-arrow before'></i><span class="label">{{'home.main-banner.button.apply' | translate}}</span><i class="bx flaticon-check icon-arrow after"></i></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
