<app-header></app-header>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'services.title' | translate}}</h2>
        </div>
    </div>
</div>

<br>
<br>
<br>
<app-services></app-services>
