import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-home-about',
  templateUrl: './homet-about.component.html',
  styleUrls: ['./homet-about.component.scss']
})
export class HometAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
