<div class="banner-section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <h1>{{'home.main-banner.title' | translate}}</h1>
                    <br>
                    <h4 style="color: white">{{'home.main-banner.description' | translate}}</h4>
                    <br>
                    <div class="btn-box">
                        <a routerLink="/apply" class="default-btn"><i class='bx flaticon-check icon-arrow before'></i><span class="label">{{'home.main-banner.button.apply' | translate}}</span><i class="bx flaticon-check icon-arrow after"></i></a>
                        <a href="https://www.youtube.com/watch?v=ciO6uhR-0mk&ab_channel=EdumentorEducation" target="_blank" class="video-btn"><span><i class='bx bx-play-circle'></i></span> {{'home.main-banner.button.watch' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divider"></div>
</div>
