<div class="row">
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bxs-group'></i>
            </div>
            <h3><span [countUp]="'home.funfacts.universities.value' | translate">00</span></h3>
            <p>{{'home.funfacts.universities.title' | translate}}</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-book-reader'></i>
            </div>
            <h3><span [countUp]="'home.funfacts.placed.value' | translate">00</span></h3>
            <p>{{'home.funfacts.placed.title' | translate}}</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-user-pin'></i>
            </div>
            <h3><span [countUp]="'home.funfacts.tomer.value' | translate">00</span></h3>
            <p>{{'home.funfacts.tomer.title' | translate}}</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bxl-deviantart'></i>
            </div>
            <h3><span [countUp]="'home.funfacts.insurance.value' | translate">00</span></h3>
            <p>{{'home.funfacts.insurance.title' | translate}}</p>
        </div>
    </div>
</div>
