import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-yos-scores',
    templateUrl: './yos-scores.component.html',
    styleUrls: ['./yos-scores.component.scss']
})
export class YosScoresComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    description: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
