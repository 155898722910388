<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{'header.contact' | translate}}</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">{{'header.contact.location.value' | translate}}</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:{{'header.contact.phone.value' | translate}}">{{'header.contact.phone.value' | translate}}</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:{{'header.contact.email.value' | translate}}">{{'header.contact.email.value' | translate}}</a>
                        </li>
                    </ul>
                    <ul class="social-link">
<!--                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>-->
<!--                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>-->
                        <li><a href="{{'header.contact.instagram.value' | translate}}" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="{{'header.contact.tiktok.value' | translate}}" class="d-block" target="_blank"><i class='bx bxl-tiktok'></i></a></li>
                        <li><a href="{{'header.contact.youtube.value' | translate}}" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
<!--                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>-->
<!--                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>-->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{'header.menu.services' | translate}}</h3>
                    <ul class="support-link">
                        <li><a routerLink="/services">{{'services.education-consultancy.title' | translate}}</a></li>
                        <li><a routerLink="/services">{{'services.translation-and-notary.title' | translate}}</a></li>
                        <li><a routerLink="/services">{{'services.dormytory-accommodation-welcome.title' | translate}}</a></li>
                        <li><a routerLink="/services">{{'services.equivalency.title' | translate}}</a></li>
                        <li><a routerLink="/services">{{'services.tomer.title' | translate}}</a></li>
                        <li><a routerLink="/services">{{'services.residence-permit.title' | translate}}</a></li>
                        <li><a routerLink="/services">{{'services.health-insurance.title' | translate}}</a></li>
                        <li><a routerLink="/services">{{'services.apostille.title' | translate}}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{'header.menu.yos' | translate}}</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/yos/what-is-yos">{{'header.menu.yos.what' | translate}}</a></li>
                        <li><a routerLink="/yos/conditions">{{'header.menu.yos.conditions' | translate}}</a></li>
                        <li><a routerLink="/yos/who-can-apply">{{'header.menu.yos.who-can-apply' | translate}}</a></li>
                        <li><a routerLink="/yos/who-can-apply">{{'header.menu.yos.important-info' | translate}}</a></li>
                        <li><a routerLink="/yos/topics">{{'header.menu.yos.topics' | translate}}</a></li>
                        <!--<li><a routerLink="/yos/examples">{{'header.menu.yos.examples' | translate}}</a></li>-->
                        <!--<li><a routerLink="/yos/scores">{{'header.menu.yos.scores' | translate}}</a></li>-->
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{'footer.newsletter.title' | translate}}</h3>
                    <div class="newsletter-box">
                        <p>{{'footer.newsletter.description' | translate}}</p>
                        <form class="newsletter-form">
                            <label>{{'footer.newsletter.email' | translate}}</label>
                            <input type="email" class="input-newsletter" placeholder="{{'footer.newsletter.email' | translate}}" name="EMAIL">
                            <button type="submit">{{'footer.newsletter.subscribe' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
            </div>
            <p>© {{'footer.rights' | translate}} <a href="https://edumentor.com.tr/" target="_blank">Edumentor</a></p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
