<!-- <app-preloader></app-preloader> -->
<router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/login' || location == '/register' || location == '/coming-soon')"></app-footer>

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">

<a routerLink="/apply" class="default-btn float-button-2">
    <i class='bx flaticon-check icon-arrow before'></i>
    <span class="label">{{ 'home.main-banner.button.apply' | translate }}</span>
    <i class="bx flaticon-check icon-arrow after"></i>
</a>

<a href="https://api.whatsapp.com/send?phone=+905369496963" class="float-button" target="_blank">
    <i class="fa fa-whatsapp float-button-margin"></i>
</a>
