// country-selector.component.ts

import {Component, EventEmitter, Output} from '@angular/core';

interface Country {
    code: string;
    phoneCode: string;
}

@Component({
    selector: 'app-country-selector',
    template: `
        <div>
            <select ngModel #phoneCode="ngModel" name="phoneCode" class="form-control" id="country" [(ngModel)]="selectedCountry" (change)="onCountryChange()">
                <option *ngFor="let country of countries" [value]="country.phoneCode">
                    {{ country.code }} ({{ country.phoneCode }})
                </option>
            </select>
        </div>
    `,
    styles: []
})
export class CountrySelectorComponent {
    @Output() selectedCodeChange = new EventEmitter<string>();

    selectedCountry = '+93';

    countries: Country[] = [
        { code: 'AF', phoneCode: '+93' },
        { code: 'AL', phoneCode: '+355' },
        { code: 'DZ', phoneCode: '+213' },
        { code: 'AD', phoneCode: '+376' },
        { code: 'AO', phoneCode: '+244' },
        { code: 'AR', phoneCode: '+54' },
        { code: 'AM', phoneCode: '+374' },
        { code: 'AU', phoneCode: '+61' },
        { code: 'AT', phoneCode: '+43' },
        { code: 'AZ', phoneCode: '+994' },
        { code: 'BS', phoneCode: '+1-242' },
        { code: 'BH', phoneCode: '+973' },
        { code: 'BD', phoneCode: '+880' },
        { code: 'BB', phoneCode: '+1-246' },
        { code: 'BY', phoneCode: '+375' },
        { code: 'BE', phoneCode: '+32' },
        { code: 'BZ', phoneCode: '+501' },
        { code: 'BJ', phoneCode: '+229' },
        { code: 'BT', phoneCode: '+975' },
        { code: 'BO', phoneCode: '+591' },
        { code: 'BA', phoneCode: '+387' },
        { code: 'BW', phoneCode: '+267' },
        { code: 'BR', phoneCode: '+55' },
        { code: 'BN', phoneCode: '+673' },
        { code: 'BG', phoneCode: '+359' },
        { code: 'BF', phoneCode: '+226' },
        { code: 'BI', phoneCode: '+257' },
        { code: 'KH', phoneCode: '+855' },
        { code: 'CM', phoneCode: '+237' },
        { code: 'CA', phoneCode: '+1' },
        { code: 'CV', phoneCode: '+238' },
        { code: 'CF', phoneCode: '+236' },
        { code: 'TD', phoneCode: '+235' },
        { code: 'CL', phoneCode: '+56' },
        { code: 'CN', phoneCode: '+86' },
        { code: 'CO', phoneCode: '+57' },
        { code: 'KM', phoneCode: '+269' },
        { code: 'CD', phoneCode: '+243' },
        { code: 'CG', phoneCode: '+242' },
        { code: 'CR', phoneCode: '+506' },
        { code: 'HR', phoneCode: '+385' },
        { code: 'CU', phoneCode: '+53' },
        { code: 'CY', phoneCode: '+357' },
        { code: 'CZ', phoneCode: '+420' },
        { code: 'DK', phoneCode: '+45' },
        { code: 'DJ', phoneCode: '+253' },
        { code: 'DM', phoneCode: '+1-767' },
        { code: 'DO', phoneCode: '+1-809' },
        { code: 'DO', phoneCode: '+1-829' },
        { code: 'EC', phoneCode: '+593' },
        { code: 'EG', phoneCode: '+20' },
        { code: 'SV', phoneCode: '+503' },
        { code: 'GQ', phoneCode: '+240' },
        { code: 'ER', phoneCode: '+291' },
        { code: 'EE', phoneCode: '+372' },
        { code: 'ET', phoneCode: '+251' },
        { code: 'FJ', phoneCode: '+679' },
        { code: 'FI', phoneCode: '+358' },
        { code: 'FR', phoneCode: '+33' },
        { code: 'GA', phoneCode: '+241' },
        { code: 'GM', phoneCode: '+220' },
        { code: 'GE', phoneCode: '+995' },
        { code: 'DE', phoneCode: '+49' },
        { code: 'GH', phoneCode: '+233' },
        { code: 'GR', phoneCode: '+30' },
        { code: 'GD', phoneCode: '+1-473' },
        { code: 'GT', phoneCode: '+502' },
        { code: 'GN', phoneCode: '+224' },
        { code: 'GW', phoneCode: '+245' },
        { code: 'GY', phoneCode: '+592' },
        { code: 'HT', phoneCode: '+509' },
        { code: 'HN', phoneCode: '+504' },
        { code: 'HU', phoneCode: '+36' },
        { code: 'IS', phoneCode: '+354' },
        { code: 'IN', phoneCode: '+91' },
        { code: 'ID', phoneCode: '+62' },
        { code: 'IR', phoneCode: '+98' },
        { code: 'IQ', phoneCode: '+964' },
        { code: 'IE', phoneCode: '+353' },
        { code: 'IL', phoneCode: '+972' },
        { code: 'IT', phoneCode: '+39' },
        { code: 'JM', phoneCode: '+1-876' },
        { code: 'JP', phoneCode: '+81' },
        { code: 'JO', phoneCode: '+962' },
        { code: 'KZ', phoneCode: '+7' },
        { code: 'KE', phoneCode: '+254' },
        { code: 'KI', phoneCode: '+686' },
        { code: 'KP', phoneCode: '+850' },
        { code: 'KR', phoneCode: '+82' },
        { code: 'KW', phoneCode: '+965' },
        { code: 'KG', phoneCode: '+996' },
        { code: 'LA', phoneCode: '+856' },
        { code: 'LV', phoneCode: '+371' },
        { code: 'LB', phoneCode: '+961' },
        { code: 'LS', phoneCode: '+266' },
        { code: 'LR', phoneCode: '+231' },
        { code: 'LY', phoneCode: '+218' },
        { code: 'LI', phoneCode: '+423' },
        { code: 'LT', phoneCode: '+370' },
        { code: 'LU', phoneCode: '+352' },
        { code: 'MK', phoneCode: '+389' },
        { code: 'MG', phoneCode: '+261' },
        { code: 'MW', phoneCode: '+265' },
        { code: 'MY', phoneCode: '+60' },
        { code: 'MV', phoneCode: '+960' },
        { code: 'ML', phoneCode: '+223' },
        { code: 'MT', phoneCode: '+356' },
        { code: 'MH', phoneCode: '+692' },
        { code: 'MR', phoneCode: '+222' },
        { code: 'MU', phoneCode: '+230' },
        { code: 'MX', phoneCode: '+52' },
        { code: 'FM', phoneCode: '+691' },
        { code: 'MD', phoneCode: '+373' },
        { code: 'MC', phoneCode: '+377' },
        { code: 'MN', phoneCode: '+976' },
        { code: 'ME', phoneCode: '+382' },
        { code: 'MA', phoneCode: '+212' },
        { code: 'MZ', phoneCode: '+258' },
        { code: 'MM', phoneCode: '+95' },
        { code: 'NA', phoneCode: '+264' },
        { code: 'NR', phoneCode: '+674' },
        { code: 'NP', phoneCode: '+977' },
        { code: 'NL', phoneCode: '+31' },
        { code: 'NZ', phoneCode: '+64' },
        { code: 'NI', phoneCode: '+505' },
        { code: 'NE', phoneCode: '+227' },
        { code: 'NG', phoneCode: '+234' },
        { code: 'NO', phoneCode: '+47' },
        { code: 'OM', phoneCode: '+968' },
        { code: 'PK', phoneCode: '+92' },
        { code: 'PW', phoneCode: '+680' },
        { code: 'PA', phoneCode: '+507' },
        { code: 'PG', phoneCode: '+675' },
        { code: 'PY', phoneCode: '+595' },
        { code: 'PE', phoneCode: '+51' },
        { code: 'PH', phoneCode: '+63' },
        { code: 'PL', phoneCode: '+48' },
        { code: 'PT', phoneCode: '+351' },
        { code: 'QA', phoneCode: '+974' },
        { code: 'RO', phoneCode: '+40' },
        { code: 'RU', phoneCode: '+7' },
        { code: 'RW', phoneCode: '+250' },
        { code: 'KN', phoneCode: '+1-869' },
        { code: 'LC', phoneCode: '+1-758' },
        { code: 'VC', phoneCode: '+1-784' },
        { code: 'WS', phoneCode: '+685' },
        { code: 'SM', phoneCode: '+378' },
        { code: 'ST', phoneCode: '+239' },
        { code: 'SA', phoneCode: '+966' },
        { code: 'SN', phoneCode: '+221' },
        { code: 'RS', phoneCode: '+381' },
        { code: 'SC', phoneCode: '+248' },
        { code: 'SL', phoneCode: '+232' },
        { code: 'SG', phoneCode: '+65' },
        { code: 'SK', phoneCode: '+421' },
        { code: 'SI', phoneCode: '+386' },
        { code: 'SB', phoneCode: '+677' },
        { code: 'SO', phoneCode: '+252' },
        { code: 'ZA', phoneCode: '+27' },
        { code: 'SS', phoneCode: '+211' },
        { code: 'ES', phoneCode: '+34' },
        { code: 'LK', phoneCode: '+94' },
        { code: 'SD', phoneCode: '+249' },
        { code: 'SR', phoneCode: '+597' },
        { code: 'SZ', phoneCode: '+268' },
        { code: 'SE', phoneCode: '+46' },
        { code: 'CH', phoneCode: '+41' },
        { code: 'SY', phoneCode: '+963' },
        { code: 'TW', phoneCode: '+886' },
        { code: 'TJ', phoneCode: '+992' },
        { code: 'TZ', phoneCode: '+255' },
        { code: 'TH', phoneCode: '+66' },
        { code: 'TL', phoneCode: '+670' },
        { code: 'TG', phoneCode: '+228' },
        { code: 'TO', phoneCode: '+676' },
        { code: 'TT', phoneCode: '+1-868' },
        { code: 'TN', phoneCode: '+216' },
        { code: 'TR', phoneCode: '+90' },
        { code: 'TM', phoneCode: '+993' },
        { code: 'TV', phoneCode: '+688' },
        { code: 'UG', phoneCode: '+256' },
        { code: 'UA', phoneCode: '+380' },
        { code: 'AE', phoneCode: '+971' },
        { code: 'GB', phoneCode: '+44' },
        { code: 'US', phoneCode: '+1' },
        { code: 'UY', phoneCode: '+598' },
        { code: 'UZ', phoneCode: '+998' },
        { code: 'VU', phoneCode: '+678' },
        { code: 'VA', phoneCode: '+379' },
        { code: 'VE', phoneCode: '+58' },
        { code: 'VN', phoneCode: '+84' },
        { code: 'YE', phoneCode: '+967' },
        { code: 'ZM', phoneCode: '+260' },
        { code: 'ZW', phoneCode: '+263' },
    ];

    onCountryChange() {
        const selectedCountry = this.countries.find(c => c.code === this.selectedCountry);
        if (selectedCountry) {
            this.selectedCodeChange.emit(selectedCountry.phoneCode);
        }
    }
}
