import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from './components/pages/home-page-three/home-page.component';
import {ErrorPageComponent} from './components/pages/error-page/error-page.component';
import {ContactPageComponent} from './components/pages/contact-page/contact-page.component';
import {YosConditionsComponent} from './components/pages/yos/yos-conditions/yos-conditions.component';
import {WhatIsYosComponent} from './components/pages/yos/what-is-yos/what-is-yos.component';
import {YosTopicsComponent} from './components/pages/yos/yos-topics/yos-topics.component';
import {YosExamplesComponent} from './components/pages/yos/yos-examples/yos-examples.component';
import {YosScoresComponent} from './components/pages/yos/yos-scores/yos-scores.component';
import {YosWhoCanApllyComponent} from './components/pages/yos/yos-who-can-apply/yos-who-can-aplly.component';
import {
    PublicUniversitiesPageComponent
} from './components/pages/universities/public-universities-page/public-universities-page.component';
import {
    PrivateUniversitiesPageComponent
} from './components/pages/universities/private-universities-page/private-universities-page.component';
import {ServicesPageComponent} from './components/pages/services-page/services-page.component';
import {AboutPageComponent} from './components/pages/about-page/about-page.component';
import {HowToApplyPageComponent} from './components/pages/how-to-apply-page/how-to-apply-page.component';
import {ExamsPageComponent} from './components/pages/exams-page/exams-page.component';
import {FaqPageComponent} from './components/pages/faq-page/faq-page.component';
import {ApplicationPageComponent} from './components/pages/application-page/application-page.component';
import {YosImportantInfoComponent} from "./components/pages/yos/yos-important-info/yos-important-info.component";

const routes: Routes = [
    {path: '', component: HomePageComponent},

    {path: 'about', component: AboutPageComponent},

    {path: 'exams', component: ExamsPageComponent},

    {path: 'faq', component: FaqPageComponent},

    {path: 'yos/what-is-yos', component: WhatIsYosComponent},
    {path: 'yos/conditions', component: YosConditionsComponent},
    {path: 'yos/topics', component: YosTopicsComponent},
    {path: 'yos/examples', component: YosExamplesComponent},
    {path: 'yos/scores', component: YosScoresComponent},
    {path: 'yos/who-can-apply', component: YosWhoCanApllyComponent},
    {path: 'yos/important-info', component: YosImportantInfoComponent},

    {path: 'services', component: ServicesPageComponent},

    {path: 'universities/state', component: PublicUniversitiesPageComponent},
    {path: 'universities/private', component: PrivateUniversitiesPageComponent},

    {path: 'application', component: HowToApplyPageComponent},
    {path: 'apply', component: ApplicationPageComponent},

    {path: 'contact', component: ContactPageComponent},

    {path: 'error-404', component: ErrorPageComponent},
    // Here add new pages component

    {path: '**', component: ErrorPageComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
