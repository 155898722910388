<section class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <!--<span class="sub-title" style="color: black">{{'home.departments.sub-title' | translate}}</span>-->
            <h2 style="max-width: 100%">{{'home.departments.title' | translate}}</h2><br>
        </div>
        <ul class="nav-tabset">
            <li *ngFor="let area of areas; index as i;"
                class="nav-tab"
                [ngClass]="{'active': area.name === currentArea.name}">
                <span [ngClass]="{'active': area.name === currentArea.name}"
                    (click)="switchTab($event, i)">{{area.name | translate}} ({{area.departments.length}})</span>
            </li>
        </ul>
        <div class="tabs-container">
            <div class="pane">
                <div class="row">
                    <div *ngFor="let department of currentArea.departments" class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <img src="{{department.imgUrl}}" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">{{department.name | translate}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
