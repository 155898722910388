import {Component} from '@angular/core';

@Component({
    selector: 'app-application-page',
    templateUrl: './application-page.component.html',
    styleUrls: ['./application-page.component.scss']
})
export class ApplicationPageComponent {

    status = '';

    submit(contactForm) {

        if (!contactForm.valid) {
            return;
        }

        const form = (document.getElementById('contact-form') as HTMLFormElement);

        fetch('https://formspree.io/f/xayggegz', {
            method: 'POST',
            body: new FormData(form),
            headers: {
                Accept: 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                this.status = 'success';
                form.reset();
            } else {
                this.status = 'fail';
            }
        });
    }
}
