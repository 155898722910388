<section class="about-area pt-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content left-content">
                    <h2 class="m-5">{{'header.menu.about' | translate}}</h2>
                    <h3 class="m-5">{{'home.about.title' | translate}}</h3>
                    <p class="m-5" style="text-align: justify">{{'home.about.description2' | translate}}</p>
                    <h6 class="m-5">{{'home.about.description1' | translate}}</h6>
                    <div class="signature m-5">
                        <img src="assets/img/logo.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-right-image">
                    <img src="assets/img/about-us.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
