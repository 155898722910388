<header class="header-area">
    <div class="top-header-style-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3">
                    <div class="top-header-logo">
                        <a routerLink="/" class="d-inline-block"><img src="assets/img/black-logo.png" alt="logo"></a>
                    </div>
                </div>
                <div class="col-lg-8">
                    <ul class="top-header-contact-info">
                        <li>
                            <i class='bx bx-phone-call'></i> <span>{{ 'header.contact.phone.title' | translate }}</span>
                            <a href="tel:{{'header.contact.phone.value' | translate}}">{{ 'header.contact.phone.value' | translate }}</a>
                        </li>
                        <li>
                            <i class='bx bx-map'></i> <span>{{ 'header.contact.location.title' | translate }}</span> <a
                            href="#">{{ 'header.contact.location.value' | translate }}</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i> <span>{{ 'header.contact.email.title' | translate }}</span>
                            <a href="mailto:{{'header.contact.email.value' | translate}}">{{ 'header.contact.email.value' | translate }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area navbar-style-two" [ngClass]="{'sticky': isSticky}">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link">{{ 'header.menu.home' | translate }}</a>
                        </li>
                        <li class="nav-item"><a routerLink="/about"
                            class="nav-link">{{ 'header.menu.about' | translate }}</a></li>
                        <li class="nav-item"><a routerLink="/application"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">{{ 'header.menu.application' | translate }}</a>
                        </li>
                        <li class="nav-item"><a routerLink="/services"
                            class="nav-link">{{ 'header.menu.services' | translate }}</a></li>
                        <li class="nav-item"><a href="javascript:void(0)"
                            class="nav-link">{{ 'header.menu.universities' | translate }}
                            <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/universities/private"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'header.menu.universities.private' | translate }}</a>
                                </li>
                                <li class="nav-item"><a routerLink="/universities/state"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'header.menu.universities.state' | translate }}</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/exams"
                            class="nav-link">{{ 'header.menu.exams' | translate }}</a></li>
                        <li class="nav-item"><a href="javascript:void(0)"
                            class="nav-link">{{ 'header.menu.yos' | translate }} <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/yos/what-is-yos"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'header.menu.yos.what' | translate }}</a>
                                </li>
                                <li class="nav-item"><a routerLink="/yos/conditions"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'header.menu.yos.conditions' | translate }}</a>
                                </li>
                                <li class="nav-item"><a routerLink="/yos/who-can-apply"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'header.menu.yos.who-can-apply' | translate }}</a>
                                </li>
                                <li class="nav-item"><a routerLink="/yos/important-info"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'header.menu.yos.important-info' | translate }}</a>
                                </li>
                                <li class="nav-item"><a routerLink="/yos/topics"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'header.menu.yos.topics' | translate }}</a>
                                </li>
                                <!--<li class="nav-item"><a routerLink="/yos/examples"-->
                                <!--    class="nav-link"-->
                                <!--    routerLinkActive="active"-->
                                <!--    [routerLinkActiveOptions]="{exact: true}">{{ 'header.menu.yos.examples' | translate }}</a>-->
                                <!--</li>-->
                                <!--<li class="nav-item"><a routerLink="/yos/scores"-->
                                <!--    class="nav-link"-->
                                <!--    routerLinkActive="active"-->
                                <!--    [routerLinkActiveOptions]="{exact: true}">{{ 'header.menu.yos.scores' | translate }}</a>-->
                                <!--</li>-->
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/faq"
                            class="nav-link">{{ 'header.menu.faq' | translate }}</a>
                        </li>
                        <li class="nav-item"><a routerLink="/contact"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">{{ 'header.menu.contact' | translate }}</a>
                        </li>
                    </ul>
                </div>
                <div class="others-option">
                    <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img src="assets/img/{{currentLang.icon}}" class="shadow" alt="image">
                            <span>{{ currentLang.name }} <i class='bx bx-chevron-down'></i></span>
                        </button>
                        <div class="dropdown-menu">
                            <a *ngFor="let lang of langs"
                                style="cursor: pointer"
                                class="dropdown-item d-flex align-items-center"
                                (click)="switchLang(lang.lang); toggleClass2();"> <img src="assets/img/{{lang.icon}}"
                                class="shadow-sm"
                                alt="flag"> <span>{{ lang.name }}</span> </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span> <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
