<app-header></app-header>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'header.menu.contact' | translate}}</h2>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>{{'header.contact.email.title' | translate}}</h3>
                    <p><a href="{{'header.contact.email.value' | translate}}">{{'header.contact.email.value' | translate}}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>{{'header.contact.location.title' | translate}}</h3>
                    <p><a href="https://maps.app.goo.gl/xexcZKs9nrFRfWfz9" target="_blank">{{'header.contact.location.value' | translate}}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>{{'header.contact.phone.title' | translate}}</h3>
                    <p><a href="tel:{{'header.contact.location.value' | translate}}">{{'header.contact.phone.value' | translate}}</a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{'contact.form.title' | translate}}</h2>
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" id="contact-form">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="{{'contact.form.name' | translate}}">
                            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                <div *ngIf="name.errors.required">{{'contact.form.validations.name-required' | translate}}</div>
                                <div *ngIf="name.errors.minlength">{{'contact.form.validations.name-min' | translate}}</div>
                                <div *ngIf="name.errors.maxlength">{{'contact.form.validations.name-max' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="{{'contact.form.email' | translate}}">
                            <div class="alert alert-danger" *ngIf="email.touched && !email.valid">{{'contact.form.validations.email-required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group input-group">
                            <app-country-selector></app-country-selector>
                            <input required ngModel name="phone" type="text" #phone="ngModel" class="form-control" id="phone" placeholder="{{'contact.form.phone' | translate}}">
                        </div>
                        <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">{{'contact.form.validations.ccode-required' | translate}}</div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject" placeholder="{{'contact.form.subject' | translate}}">
                            <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">{{'contact.form.validations.subject-required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="{{'contact.form.message' | translate}}"></textarea>
                            <div class="alert alert-danger" *ngIf="message.touched && !name.valid">{{'contact.form.validations.message-required' | translate}}</div>
                        </div>
                    </div>
                    <div class="alert alert-success" *ngIf="status === 'success'">{{'contact.form.responses.success' | translate}}</div>
                    <div class="alert alert-danger" *ngIf="status === 'fail'">{{'contact.form.responses.fail' | translate}}</div>
                    <div class="col-lg-12 col-md-12">
                        <button (click)="submit(contactForm)" class="default-btn" [class.disabled]="!contactForm.valid">
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                            <span class="label">{{'contact.form.button' | translate}}</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d12044.528613622915!2d29.1208671!3d41.0004806!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDAwJzE0LjQiTiAyOcKwMDcnMzAuNSJF!5e0!3m2!1str!2str!4v1701017463189!5m2!1str!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
