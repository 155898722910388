import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-what-is-yos',
    templateUrl: './what-is-yos.component.html',
    styleUrls: ['./what-is-yos.component.scss']
})
export class WhatIsYosComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    description: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
