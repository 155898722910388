<app-header></app-header>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'yos.conditions.title' | translate}}</h2>
        </div>
    </div>
</div>

<div class="story-area ptb-100">
    <app-info [title]="'yos.conditions.title' | translate" imgUrl="assets/img/yos/conditions.jpg" [description]="'yos.conditions.description' | translate"></app-info>
</div>
