<app-header></app-header>

<app-main-banner></app-main-banner>

<app-home-about></app-home-about>

<div class="courses-categories-area bg-F7F9FB pt-100 pb-70">
    <div class="container">
        <!--<div class="section-title text-start">-->
        <!--    <h2 style="color: red">{{'home.services.title' | translate}}</h2>-->
        <!--    <br/>-->
        <!--    <h5>{{'home.services.description' | translate}}</h5>-->
        <!--</div>-->
        <app-services></app-services>
    </div>
</div>

<!--<div class="testimonials-area pt-100">-->
<!--    <div class="container">-->
<!--        <div class="section-title text-start">-->
<!--            <h2>Öğrenciler Ne Söylüyor</h2>-->
<!--        </div>-->
<!--        <app-feedbacks></app-feedbacks>-->
<!--    </div>-->
<!--</div>-->

<app-home-departments></app-home-departments>

<!--<div class="courses-categories-area bg-image pt-100">-->
<!--    <div class="container">-->
<!--        <div class="section-title text-start">-->
<!--            &lt;!&ndash;<span class="sub-title" style="color: black">{{'home.universities.sub-title' | translate}}</span>&ndash;&gt;-->
<!--            <h2 style="max-width: 100%;">{{'home.universities.title' | translate}}</h2>-->
<!--            <br>-->
<!--            <br>-->
<!--&lt;!&ndash;            <a routerLink="/courses-category-style-2" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">{{'home.universities.view-all' | translate}}</span><i class="bx bx-show-alt icon-arrow after"></i></a>&ndash;&gt;-->
<!--        </div>-->
<!--        <app-universities-carousel></app-universities-carousel>-->
<!--    </div>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--</div>-->
