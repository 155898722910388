import {Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

  constructor(
      private scroller: ViewportScroller,
      private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
      this.route.fragment.subscribe(fragment => {
          if (fragment) {
              const element = document.querySelector('#' + fragment);
              if (element) {
                  element.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
          }
      });
  }

}
