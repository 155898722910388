import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '../../../services/translate.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    constructor(
        private translateService: TranslateService,
        private router: Router
    ) {
    }

    isSticky = false;
    langs = [
        {
            lang: 'tr',
            name: 'TR',
            icon: 'tr-flag.jpg'
        },
        {
            lang: 'en',
            name: 'EN',
            icon: 'us-flag.jpg'
        },
        {
            lang: 'ru',
            name: 'RU',
            icon: 'russia-flag.jpg'
        },
        // {
        //     lang: 'ar',
        //     name: 'AR',
        //     icon: 'ar-flag.jpg'
        // }
    ];
    currentLang = this.langs.filter(l => l.lang === localStorage.getItem('currentLang'))[0] || this.langs[2];

    classApplied = false;

    classApplied2 = false;

    classApplied3 = false;

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    ngOnInit(): void {
    }

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    switchLang(lang) {
        this.currentLang = this.langs.filter(l => l.lang === lang)[0];
        localStorage.setItem('currentLang', lang);
    }

    navigateWithAnchor(path, anchor) {
        this.router.navigate(['/' + path + '#' + anchor]);
    }
}
