<app-header></app-header>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'about.slogan' | translate}}</h2>
        </div>
    </div>
</div>

<div class="story-area ptb-100">
    <app-info [title]="'about.title' | translate" imgUrl="assets/img/about-us.jpg" [description]="'about.description' | translate"></app-info>
</div>
