<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="section-title text-start">
                <h2>{{title}}</h2>
                <br>
                <img [src]="imgUrl" alt="image">
            </div>
        </div>
        <div class="col-lg-8 col-md-12">
            <div class="story-content p-5">
                <p [innerHTML]="description" style="text-align: justify"></p>
            </div>
        </div>
    </div>
</div>
