<app-header></app-header>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'header.menu.universities' | translate}}</h2>
        </div>
    </div>
</div>

<div class="story-area ptb-100">
    <app-info [title]="'header.menu.universities.private' | translate" imgUrl="assets/img/public-unis.jpg" [description]="'universities.private' | translate"></app-info>
</div>
