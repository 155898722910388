import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-universities-carousel',
    templateUrl: './universities-carousel.component.html',
    styleUrls: ['./universities-carousel.component.scss']
})
export class UniversitiesCarouselComponent implements OnInit {

    categoriesSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            '<i class="bx bx-left-arrow-alt"></i>',
            '<i class="bx bx-right-arrow-alt"></i>'
        ],
        responsive: {
            0: {
                items: 2
            },
            576: {
                items: 3
            },
            768: {
                items: 5
            },
            1200: {
                items: 6
            }
        }
    };

    universities = [
        {
            name: 'ALANYA ÜNİVERSİTESİ',
            img: 'assets/img/universties/alanya.png'
        },
        {
            name: 'ALTINBAŞ ÜNİVERSİTESİ',
            img: 'assets/img/universties/altinbas.png'
        },
        {
            name: 'ANKARA MEDİPOL ÜNİVERSİTESİ',
            img: 'assets/img/universties/medipol_ankara.png'
        },
        {
            name: 'ANTALYA BİLİM ÜNİVERSİTESİ',
            img: 'assets/img/universties/antalya_bilim.png'
        },
        {
            name: 'BAHÇEŞEHİR ÜNİVERSİTESİ',
            img: 'assets/img/universties/bahcesehir.png'
        },
        {
            name: 'BİRUNİ ÜNİVERSİTESİ',
            img: 'assets/img/universties/biruni.png'
        },
        {
            name: 'FENERBAHÇE ÜNİVERSİTESİ',
            img: 'assets/img/universties/fenerbahce.png'
        },
        {
            name: 'HALİÇ ÜNİVERSİTESİ',
            img: 'assets/img/universties/halic.png'
        },
        {
            name: 'İSTANBUL AREL ÜNİVERSİTESİ',
            img: 'assets/img/universties/arel.png'
        },
        {
            name: 'MUDANYA ÜNİVERSİTESİ',
            img: 'assets/img/universties/mudanya.png'
        },
        {
            name: 'İSTANBUL MEDİPOL ÜNİVERSİTESİ',
            img: 'assets/img/universties/istanbul_medipol.png'
        },
        {
            name: 'İSTANBUL NİŞANTAŞI ÜNİVERSİTESİ',
            img: 'assets/img/universties/nisantasi.png'
        },
        {
            name: 'İSTANBUL OKAN ÜNİVERSİTESİ',
            img: 'assets/img/universties/okan.png'
        },
        {
            name: 'İSTANBUL SABAHATTİN ZAİM ÜNİVERSİTESİ',
            img: 'assets/img/universties/zaim.png'
        },
        {
            name: 'KADİR HAS ÜNİVERSİTESİ',
            img: 'assets/img/universties/kadir_has.png'
        },
        {
            name: 'KOCAELİ SAĞLIK VE TEKNOLOJİ ÜNİVERSİTESİ',
            img: 'assets/img/universties/kostu.png'
        },
        {
            name: 'ÜSKÜDAR ÜNİVERSİTESİ',
            img: 'assets/img/universties/uskudar.png'
        },
        {
            name: 'ANADOLU ÜNİVERSİTESİ',
            img: 'assets/img/universties/anadolu.png'
        },
        {
            name: 'ANKARA HACI BAYRAM VELİ ÜNİVERSİTESİ',
            img: 'assets/img/universties/haci_bayram_veli.png'
        },
        {
            name: 'ANKARA SOSYAL BİLİMLER ÜNİVERSİTESİ',
            img: 'assets/img/universties/ankara_sosyal_bilimler.png'
        },
        {
            name: 'ANKARA YILDIRIM BEYAZIT ÜNİVERSİTESİ',
            img: 'assets/img/universties/yildirim_beyazit.png'
        },
        {
            name: 'BURDUR MEHMET AKİF ERSOY ÜNİVERSİTESİ',
            img: 'assets/img/universties/maku.png'
        },
        {
            name: 'ATATÜRK ÜNİVERSİTESİ',
            img: 'assets/img/universties/ataturk.png'
        },
        {
            name: 'BALIKESİR ÜNİVERSİTESİ',
            img: 'assets/img/universties/balikesir.png'
        },
        {
            name: 'BANDIRMA ONYEDİ EYLÜL ÜNİVERSİTESİ',
            img: 'assets/img/universties/bandirma.png'
        },
        {
            name: 'BİLECİK ŞEYH EDEBALİ ÜNİVERSİTESİ',
            img: 'assets/img/universties/bilecik_seyh_edebali.png'
        },
        {
            name: 'OSTİM TEKNİK ÜNİVERSİTESİ',
            img: 'assets/img/universties/ostim.png'
        },
        {
            name: 'BOLU ABANT İZZET BAYSAL ÜNİVERSİTESİ',
            img: 'assets/img/universties/abant_izzet.png'
        },
        {
            name: 'BURSA TEKNİK ÜNİVERSİTESİ',
            img: 'assets/img/universties/bursa_teknik.png'
        },
        {
            name: 'ÇANAKKALE ONSEKİZ MART ÜNİVERSİTESİ',
            img: 'assets/img/universties/onsekiz_mart.png'
        },
        {
            name: 'ERZİNCAN BİNALİ YILDIRIM ÜNİVERSİTESİ',
            img: 'assets/img/universties/binali_yildirim.png'
        },
        {
            name: 'ESKİŞEHİR OSMANGAZİ ÜNİVERSİTESİ',
            img: 'assets/img/universties/osmangazi.png'
        },
        {
            name: 'GAZİANTEP ÜNİVERSİTESİ',
            img: 'assets/img/universties/gaziantep.png'
        },
        {
            name: 'HİTİT ÜNİVERSİTESİ',
            img: 'assets/img/universties/hitit.png'
        },
        {
            name: 'İSTANBUL MEDENİYET ÜNİVERSİTESİ',
            img: 'assets/img/universties/medeniyet.png'
        },
        {
            name: 'İZMİR DEMOKRASİ ÜNİVERSİTESİ',
            img: 'assets/img/universties/demokrasi.png'
        },
        {
            name: 'İZMİR KATİP ÇELEBİ ÜNİVERSİTESİ',
            img: 'assets/img/universties/katip_celebi.png'
        },
        {
            name: 'KARABÜK ÜNİVERSİTESİ',
            img: 'assets/img/universties/karabuk.png'
        },
        {
            name: 'KAYSERİ ÜNİVERSİTESİ',
            img: 'assets/img/universties/kayseri.png'
        },
        {
            name: 'KOCAELİ ÜNİVERSİTESİ',
            img: 'assets/img/universties/kocaeli.png'
        },
        {
            name: 'KÜTAHYA DUMLUPINAR ÜNİVERSİTESİ',
            img: 'assets/img/universties/dumlupinar.png'
        },
        {
            name: 'ONDOKUZ MAYIS ÜNİVERSİTESİ',
            img: 'assets/img/universties/ondokuzmayis.png'
        },
        {
            name: 'SAKARYA UYGULAMALI BİLİMLER ÜNİVERSİTESİ',
            img: 'assets/img/universties/sakarya_ub.png'
        },
        {
            name: 'SİVAS CUMHURİYET ÜNİVERSİTESİ',
            img: 'assets/img/universties/cumhuriyet.png'
        },
        {
            name: 'ÖZYEĞİN ÜNİVERSİTESİ',
            img: 'assets/img/universties/ozyegin.png'
        },
        {
            name: 'TEKİRDAĞ NAMIK KEMAL ÜNİVERSİTESİ',
            img: 'assets/img/universties/tekirdag.png'
        },
        {
            name: 'TOKAT GAZİOSMANPAŞA ÜNİVERSİTESİ',
            img: 'assets/img/universties/gaziosmanpasa.png'
        },
        {
            name: 'YALOVA ÜNİVERSİTESİ',
            img: 'assets/img/universties/yalova.png'
        },
        {
            name: 'ZONGULDAK BÜLENT ECEVİT ÜNİVERSİTESİ',
            img: 'assets/img/universties/bulent_ecevit.png'
        }
    ];

    constructor() {
    }

    ngOnInit(): void {
    }
}
