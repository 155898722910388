<app-header></app-header>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'yos.who-can-aplly.title' | translate}}</h2>
        </div>
    </div>
</div>

<div class="story-area ptb-100">
    <app-info [title]="'yos.who-can-aplly.title' | translate" imgUrl="assets/img/yos/who-can-apply.jpg" [description]="'yos.who-can-aplly.description' | translate"></app-info>
</div>
