<app-header></app-header>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'header.menu.exams' | translate}}</h2>
        </div>
    </div>
</div>

<div class="motivation-offer-area">
    <div class="container">
        <div class="row">
            <div class="motivation-offer-content">
                <div class="row">
                    <h3 class="mb-5" style="text-align: center">{{'exams.sub-title' | translate}}</h3>
                    <div class="offer-inner-box col-lg-5 mx-5 col-md-12">
                        <div class="icon">
                            <i class="bx bx-alarm"></i>
                        </div>
                        <h4>{{'exams.diploma.title' | translate}}</h4>
                        <p>{{'exams.diploma.description' | translate}}</p>
                    </div>
                    <div class="offer-inner-box col-lg-5 mx-5 col-md-12">
                        <div class="icon">
                            <i class="bx bx-alarm"></i>
                        </div>
                        <h4>{{'exams.tr-yos.title' | translate}}</h4>
                        <p>{{'exams.tr-yos.description' | translate}}</p>
                    </div>
                    <div class="offer-inner-box col-lg-5 mx-5 col-md-12">
                        <div class="icon">
                            <i class="bx bx-alarm"></i>
                        </div>
                        <h4>{{'exams.turkish-schoolarship.title' | translate}}</h4>
                        <p>{{'exams.turkish-schoolarship.description' | translate}}</p>
                    </div>
                    <div class="offer-inner-box col-lg-5 mx-5 col-md-12">
                        <div class="icon">
                            <i class="bx bx-alarm"></i>
                        </div>
                        <h4>{{'exams.sat.title' | translate}}</h4>
                        <p>{{'exams.sat.description' | translate}}</p>
                    </div>
                    <div class="offer-inner-box col-lg-5 mx-5 col-md-12">
                        <div class="icon">
                            <i class="bx bx-alarm"></i>
                        </div>
                        <h4>{{'exams.act.title' | translate}}</h4>
                        <p>{{'exams.act.description' | translate}}</p>
                    </div>
                    <div class="offer-inner-box col-lg-5 mx-5 col-md-12">
                        <div class="icon">
                            <i class="bx bx-alarm"></i>
                        </div>
                        <h4>{{'exams.gce.title' | translate}}</h4>
                        <p>{{'exams.gce.description' | translate}}</p>
                    </div>
                    <div class="offer-inner-box col-lg-5 mx-5 col-md-12">
                        <div class="icon">
                            <i class="bx bx-alarm"></i>
                        </div>
                        <h4>{{'exams.ib.title' | translate}}</h4>
                        <p>{{'exams.ib.description' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
