<app-header></app-header>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'header.menu.faq' | translate}}</h2>
        </div>
    </div>
</div>

<div class="motivation-offer-area">
    <div class="container">
        <div class="row">
            <h3 class="m-5" style="text-align: center">{{'sss.description' | translate}}</h3>
            <app-faq class="mb-5"></app-faq>
        </div>
    </div>
</div>
