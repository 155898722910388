import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-public-universities-page',
    templateUrl: './public-universities-page.component.html',
    styleUrls: ['./public-universities-page.component.scss']
})
export class PublicUniversitiesPageComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    description: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
