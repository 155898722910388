import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-home-departments',
    templateUrl: './home-departments.component.html',
    styleUrls: ['./home-departments.component.scss']
})
export class HomeDepartmentsComponent implements OnInit {

    constructor() { }

    areas = [
        {
            name: 'home.departments.health.title',
            departments: [
                {
                    name: 'home.departments.health.medicine',
                    imgUrl: 'assets/img/departments/tip.jpg'
                },
                {
                    name: 'home.departments.health.dentistry',
                    imgUrl: 'assets/img/departments/dis.jpg'
                },
                {
                    name: 'home.departments.health.veterinary',
                    imgUrl: 'assets/img/departments/veterinerlik.jpg'
                },
                {
                    name: 'home.departments.health.pharmacy',
                    imgUrl: 'assets/img/departments/eczacilik.jpg'
                },
                {
                    name: 'home.departments.health.physiotherapy_and_ehabilitation',
                    imgUrl: 'assets/img/departments/ftr.jpg'
                },
                {
                    name: 'home.departments.health.midwifery',
                    imgUrl: 'assets/img/departments/ebelik.jpg'
                },
                {
                    name: 'home.departments.health.nursing',
                    imgUrl: 'assets/img/departments/hemsirelik.jpg'
                }
            ]
        },
        {
            name: 'home.departments.engineering.title',
            departments: [
                {
                    name: 'home.departments.engineering.computer_engineering',
                    imgUrl: 'assets/img/departments/okan-banner-ic-fakulteler-mm-bilgisayar.jpg'
                },
                {
                    name: 'home.departments.engineering.software_engineering',
                    imgUrl: 'assets/img/departments/bilgisayar_muhendisi.jpg'
                },
                {
                    name: 'home.departments.engineering.aerospace_engineering',
                    imgUrl: 'assets/img/departments/hava-ve-uzay-hekimligi-maasi.jpg'
                },
                {
                    name: 'home.departments.engineering.aeronautical_engineering',
                    imgUrl: 'assets/img/departments/ucak-bakim-muhendisi-maaslari.jpg'
                },
                {
                    name: 'home.departments.engineering.marine_machinery_engineering',
                    imgUrl: 'assets/img/departments/gemi_makineleri.jpg'
                },
                {
                    name: 'home.departments.engineering.geophysics_engineering',
                    imgUrl: 'assets/img/departments/64401.jpg'
                },
                {
                    name: 'home.departments.engineering.civil_engineering',
                    imgUrl: 'assets/img/departments/5b6b7bb7bc2cb51d847958cd_0.jpeg'
                },
                {
                    name: 'home.departments.engineering.ship_building_engineering',
                    imgUrl: 'assets/img/departments/gemi-insaati-ve-gemi-makineleri-muhendisligi-maaslari.jpg'
                },
                {
                    name: 'home.departments.engineering.artificial_intelligence_engineering',
                    imgUrl: 'assets/img/departments/a86d75ca8c377d7e8cf4fd3cbe68b4968a3db30a.png'
                },
                {
                    name: 'home.departments.engineering.textile_engineering',
                    imgUrl: 'assets/img/departments/IMG_5D86FB-C80504-E71CA3-F9E0CE-6C81D2-5270EF.jpg'
                },
                {
                    name: 'home.departments.engineering.mechanical_engineering',
                    imgUrl: 'assets/img/departments/KIZOA-Movie-Maker-ud8qgzjl.gif'
                },
                {
                    name: 'home.departments.engineering.automotive_engineering',
                    imgUrl: 'assets/img/departments/otomotiv-muhendisligi-maaslari.jpg'
                },
                {
                    name: 'home.departments.engineering.mechatronics_engineering',
                    imgUrl: 'assets/img/departments/Mekatronik-Muhendisligi.jpg'
                },
                {
                    name: 'home.departments.engineering.mathematics_engineering',
                    imgUrl: 'assets/img/departments/df3d9210ba80b2fc1c6c287681d455e1.jpg'
                },
                {
                    name: 'home.departments.engineering.geological_engineering',
                    imgUrl: 'assets/img/departments/bd73632aed.jpg'
                },
                {
                    name: 'home.departments.engineering.management_engineering',
                    imgUrl: 'assets/img/departments/5c563421bc2cb52fa40c09e0_0.jpeg'
                },
                {
                    name: 'home.departments.engineering.genetic_engineering',
                    imgUrl: 'assets/img/departments/biyoloji-1024x499.jpg'
                },
                {
                    name: 'home.departments.engineering.biomedical_engineering',
                    imgUrl: 'assets/img/departments/vpofu1r2jmc6t-WhatsApp Image 2021-04-02 at 17.41.38.jpeg'
                },
                {
                    name: 'home.departments.engineering.environmental_engineering',
                    imgUrl: 'assets/img/departments/CevreMuhendisligi.jpg'
                }
            ]
        },
        {
            name: 'home.departments.economics_administrative_and_social_sciences.title',
            departments: [
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.management_information_systems',
                    imgUrl: 'assets/img/departments/f0c1f4a0dfbdac5ca8608c2f47e6295e1b70c6ec.jpg'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.international_trade_and_business',
                    imgUrl: 'assets/img/departments/uluslararasi-ticaret-ve-isletmecilik-dgs-2022-taban-puanlari-ve-bolum-kontenjanlari-847.jpg'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.business',
                    imgUrl: 'assets/img/departments/800x420-isletme-mezunlari-ne-is-yapar-isletme-bolumu-mezunlari-is-olanaklari-nelerdir-k1-1592821020895.jpg'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.logistics',
                    imgUrl: 'assets/img/departments/1-768x452.png'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.international_relations',
                    imgUrl: 'assets/img/departments/gxni.jpg'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.political_science_international_relations',
                    imgUrl: 'assets/img/departments/d_6.jpg'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.international_trade_and_logistics',
                    imgUrl: 'assets/img/departments/ulus2.jpg'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.accounting',
                    imgUrl: 'assets/img/departments/650x344-muhasebe-ve-vergi-uygulamalari-bolumu-mezunlari-ne-is-yapar-muhasebe-ve-vergi-uygulamalari-mezunlari-is-olanaklari-k1-15.jpg'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.finance_management',
                    imgUrl: 'assets/img/departments/Finans_ve_muhasebe_arasindaki_farklar.png'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.tourism_management',
                    imgUrl: 'assets/img/departments/tuturizm-isletmeciligi-ve-otelcilik0447c.png'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.economy',
                    imgUrl: 'assets/img/departments/265421-555600118.jpeg'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.public_administration',
                    imgUrl: 'assets/img/departments/announcement-img4.jpg'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.finance_and_banking',
                    imgUrl: 'assets/img/departments/bankacilik.png'
                },
                {
                    name: 'home.departments.economics_administrative_and_social_sciences.human_resources_management',
                    imgUrl: 'assets/img/departments/content_8_47295b15f83434e88.jpg'
                }
            ]
        },
        {
            name: 'home.departments.law_faculty.title',
            departments: [
                {
                    name: 'home.departments.law_faculty.law',
                    imgUrl: 'assets/img/departments/Downloader.la-6167bfa5ae77c_1200x628-780x470.jpg'
                }
            ]
        },
        {
            name: 'home.departments.faculty_of_fine_arts.title',
            departments: [
                {
                    name: 'home.departments.faculty_of_fine_arts.theatre',
                    imgUrl: 'assets/img/departments/photo-1503095396549-807759245b35.jpg'
                },
                {
                    name: 'home.departments.faculty_of_fine_arts.acting',
                    imgUrl: 'assets/img/departments/a-movie-clapper-board-film-roll-and-sprocket-wheel.jpg'
                },
                {
                    name: 'home.departments.faculty_of_fine_arts.fashion_design',
                    imgUrl: 'assets/img/departments/Fashion-designing-professionals.jpg'
                },
                {
                    name: 'home.departments.faculty_of_fine_arts.interior_architecture',
                    imgUrl: 'assets/img/departments/ic-mimarlik-dersleri.jpg'
                },
                {
                    name: 'home.departments.faculty_of_fine_arts.industrial_products_design',
                    imgUrl: 'assets/img/departments/endustri-tasarimi.jpg'
                },
                {
                    name: 'home.departments.faculty_of_fine_arts.graphic_design',
                    imgUrl: 'assets/img/departments/grafik-tasarim-nedir-tasarimci-ne-is-yapar.jpg'
                },
                {
                    name: 'home.departments.faculty_of_fine_arts.cartoon_and_animation',
                    imgUrl: 'assets/img/departments/k_25114140_k_22175617_manset.jpg'
                },
                {
                    name: 'home.departments.faculty_of_fine_arts.sculpture',
                    imgUrl: 'assets/img/departments/Capture.PNG'
                },
                {
                    name: 'home.departments.faculty_of_fine_arts.music',
                    imgUrl: 'assets/img/departments/muzik-bolumu-olan-universiteler.jpg'
                },
                {
                    name: 'home.departments.faculty_of_fine_arts.painting',
                    imgUrl: 'assets/img/departments/gsf.jpg'
                }
            ]
        },
        {
            name: 'home.departments.faculty_of_architecture.title',
            departments: [
                {
                    name: 'home.departments.faculty_of_architecture.architecture',
                    imgUrl: 'assets/img/departments/Dunyanin-En-Iyi-Mimarlik-Fakulteleri-Hangileridir.jpg'
                },
                {
                    name: 'home.departments.faculty_of_architecture.landscape_architecture',
                    imgUrl: 'assets/img/departments/slaytmobil.jpg'
                },
                {
                    name: 'home.departments.faculty_of_architecture.interior_architecture',
                    imgUrl: 'assets/img/departments/1-1.jpg'
                },
                {
                    name: 'home.departments.faculty_of_architecture.interior_architecture_and_environmental_design',
                    imgUrl: 'assets/img/departments/jgswt52zvldw3-İçmimarlık Jüri 4.jpg'
                },
                {
                    name: 'home.departments.faculty_of_architecture.industrial_design',
                    imgUrl: 'assets/img/departments/aday_ogrenciler_endustriyel_tasarim_bolumu_sayfasi_foto1.jpeg'
                },
                {
                    name: 'home.departments.faculty_of_architecture.visual_communication_design',
                    imgUrl: 'assets/img/departments/departments1.jpg'
                }
            ]
        },
        {
            name: 'home.departments.faculty_of_educational_sciences.title',
            departments: [
                {
                    name: 'home.departments.faculty_of_educational_sciences.guidance_and_psychological_counselling',
                    imgUrl: 'assets/img/departments/kapak-44.png'
                },
                {
                    name: 'home.departments.faculty_of_educational_sciences.physical_education_and_sports_teaching',
                    imgUrl: 'assets/img/departments/ff8f792b-f834-44e4-9b13-edd9dd67ceba-ac90b739-ad2f-43d1-8d3a-0f2d4cc4c96b.jpeg'
                },
                {
                    name: 'home.departments.faculty_of_educational_sciences.preschool_education',
                    imgUrl: 'assets/img/departments/1_uQI5cEMM8kFUFNLclSxmcA.jpg'
                },
                {
                    name: 'home.departments.faculty_of_educational_sciences.language_teaching',
                    imgUrl: 'assets/img/departments/ingilizce-ogretmenligi-bolumu.jpg'
                },
                {
                    name: 'home.departments.faculty_of_educational_sciences.teacher_training_in_mathematics',
                    imgUrl: 'assets/img/departments/ilkogretim-matematik-ogretmenligi-bolumu-ne-durumda-ilkogretim-matematik-ogretmenligi-taban-puanlari-2019-h1547545585-1196ec.jpg'
                },
                {
                    name: 'home.departments.faculty_of_educational_sciences.classroom_teaching',
                    imgUrl: 'assets/img/departments/images (1).jpg'
                },
                {
                    name: 'home.departments.faculty_of_educational_sciences.physics_teacher',
                    imgUrl: 'assets/img/departments/s5624he4dee827c4ad332fe83711d87a06df090c105f1b.jpg'
                },
                {
                    name: 'home.departments.faculty_of_educational_sciences.teacher_education_chemistry',
                    imgUrl: 'assets/img/departments/kimya.jpg'
                }
            ]
        },
        {
            name: 'home.departments.faculty_of_literature.title',
            departments: [
                {
                    name: 'home.departments.faculty_of_literature.psychology',
                    imgUrl: 'assets/img/departments/skills-min.png'
                },
                {
                    name: 'home.departments.faculty_of_literature.english_translation_and_interpreting',
                    imgUrl: 'assets/img/departments/mutercim-tercumanlik1.jpg'
                },
                {
                    name: 'home.departments.faculty_of_literature.molecular_biology_and_genetics',
                    imgUrl: 'assets/img/departments/Moleküler Biyoloji ve Genetik'
                },
                {
                    name: 'home.departments.faculty_of_literature.archaeology',
                    imgUrl: 'assets/img/departments/1_424.jpg'
                },
                {
                    name: 'home.departments.faculty_of_literature.caucasian_languages_and_cultures',
                    imgUrl: 'assets/img/departments/departments1.jpg'
                },
                {
                    name: 'home.departments.faculty_of_literature.sociology',
                    imgUrl: 'assets/img/departments/sosyoloji.jpg'
                },
                {
                    name: 'home.departments.faculty_of_literature.history',
                    imgUrl: 'assets/img/departments/tarih.jpg'
                },
                {
                    name: 'home.departments.faculty_of_literature.philosophy',
                    imgUrl: 'assets/img/departments/1_0oUFwHc8KlRCYGmdzHYwNA.jpg'
                },
                {
                    name: 'home.departments.faculty_of_literature.turkish_language_literature',
                    imgUrl: 'assets/img/departments/edebiyat.jpg'
                },
                {
                    name: 'home.departments.faculty_of_literature.biology',
                    imgUrl: 'assets/img/departments/_research.jpg'
                },
                {
                    name: 'home.departments.faculty_of_literature.geography',
                    imgUrl: 'assets/img/departments/cografya-bolumu-is-imkanlari-scaled.jpg'
                },
                {
                    name: 'home.departments.faculty_of_literature.information_and_document_management',
                    imgUrl: 'assets/img/departments/stuttgart-city-library-3_2-800x500_q85.jpg'
                },
                {
                    name: 'home.departments.faculty_of_literature.history_of_art',
                    imgUrl: 'assets/img/departments/3616036_16e4.jpg'
                }
            ]
        },
        {
            name: 'home.departments.faculty_of_tourism.title',
            departments: [
                {
                    name: 'home.departments.faculty_of_tourism.gastronomy_and_culinary_arts',
                    imgUrl: 'assets/img/departments/Hasan-Kalyoncu-Universitesi-Gastronomi-ve-Mutfak-Sanatlari-Bolumu-6-1024x683.jpg'
                },
                {
                    name: 'home.departments.faculty_of_tourism.tourism_management',
                    imgUrl: 'assets/img/departments/a16ddccd976098ef6a73dc7c0cb6fe31.jpg'
                },
                {
                    name: 'home.departments.faculty_of_tourism.tourism_guidance',
                    imgUrl: 'assets/img/departments/rehber-696x394.jpg'
                }
            ]
        }
    ];

    // for tab click event
    currentArea = this.areas[0];
    isViewInit = false;

    ngOnInit(): void {}

    switchTab(event: MouseEvent, tab: number) {
        event.preventDefault();
        this.currentArea = this.areas[tab];
    }
}
