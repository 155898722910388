import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-services-page',
    templateUrl: './services-page.component.html',
    styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    description: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
