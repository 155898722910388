import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CountUpModule} from 'ngx-countup';
import {NgxScrollTopModule} from 'ngx-scrolltop';
import {LightboxModule} from 'ngx-lightbox';
import {FormsModule} from '@angular/forms';
import {LightgalleryModule} from 'lightgallery/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {FunfactsComponent} from './components/common/funfacts/funfacts.component';
import {HomePageComponent} from './components/pages/home-page-three/home-page.component';
import {ServicesComponent} from './components/common/services/services.component';
import {
    UniversitiesCarouselComponent
} from './components/pages/home-page-three/universties-carousel/universities-carousel.component';
import {HomeDepartmentsComponent} from './components/pages/home-page-three/home-departments/home-departments.component';
import {HometAboutComponent} from './components/pages/home-page-three/home-about/homet-about.component';
import {MainBannerComponent} from './components/pages/home-page-three/main-banner/main-banner.component';
import {HeaderComponent} from './components/common/header/header.component';
import {FeedbacksComponent} from './components/pages/home-page-three/feedbacks/feedbacks.component';
import {ServiceListComponent} from './components/pages/home-page-three/service-list/service-list.component';
import {AboutPageComponent} from './components/pages/about-page/about-page.component';
import {InfoComponent} from './components/common/info/info.component';
import {ErrorPageComponent} from './components/pages/error-page/error-page.component';
import {ContactPageComponent} from './components/pages/contact-page/contact-page.component';
import {HowToApplyPageComponent} from './components/pages/how-to-apply-page/how-to-apply-page.component';
import {HttpClientModule} from '@angular/common/http';
import {TranslatePipe} from './pipes/translate.pipe';
import {WhatIsYosComponent} from './components/pages/yos/what-is-yos/what-is-yos.component';
import {YosConditionsComponent} from './components/pages/yos/yos-conditions/yos-conditions.component';
import {YosTopicsComponent} from './components/pages/yos/yos-topics/yos-topics.component';
import {YosExamplesComponent} from './components/pages/yos/yos-examples/yos-examples.component';
import {YosScoresComponent} from './components/pages/yos/yos-scores/yos-scores.component';
import {YosWhoCanApllyComponent} from './components/pages/yos/yos-who-can-apply/yos-who-can-aplly.component';
import {ServicesPageComponent} from './components/pages/services-page/services-page.component';
import {
    PublicUniversitiesPageComponent
} from './components/pages/universities/public-universities-page/public-universities-page.component';
import {
    PrivateUniversitiesPageComponent
} from './components/pages/universities/private-universities-page/private-universities-page.component';
import {ExamsPageComponent} from './components/pages/exams-page/exams-page.component';
import {FaqPageComponent} from './components/pages/faq-page/faq-page.component';
import {FaqComponent} from './components/pages/faq-page/faq/faq.component';
import {ApplicationPageComponent} from './components/pages/application-page/application-page.component';
import {CountrySelectorComponent} from './components/common/country-code-selector/country-code-selector.component';
import {YosImportantInfoComponent} from "./components/pages/yos/yos-important-info/yos-important-info.component";

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        FunfactsComponent,
        HomePageComponent,
        ServicesComponent,
        UniversitiesCarouselComponent,
        HomeDepartmentsComponent,
        HometAboutComponent,
        MainBannerComponent,
        HeaderComponent,
        FeedbacksComponent,
        ServiceListComponent,
        AboutPageComponent,
        InfoComponent,
        ErrorPageComponent,
        ContactPageComponent,
        HowToApplyPageComponent,
        TranslatePipe,
        WhatIsYosComponent,
        YosConditionsComponent,
        YosTopicsComponent,
        YosExamplesComponent,
        YosScoresComponent,
        YosWhoCanApllyComponent,
        YosImportantInfoComponent,
        ServicesPageComponent,
        PrivateUniversitiesPageComponent,
        PublicUniversitiesPageComponent,
        ExamsPageComponent,
        FaqPageComponent,
        FaqComponent,
        ApplicationPageComponent,
        CountrySelectorComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CarouselModule,
        CountUpModule,
        NgxScrollTopModule,
        LightboxModule,
        FormsModule,
        LightgalleryModule,
        HttpClientModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
